* {
  box-sizing: border-box;
}

body {
  margin: 0;
  color: #333333;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.main {
  /* text-align: center; */
  display: flex;
  flex-direction: column;
}

.mainContainer {
  flex: 1;
  min-height: calc(100vh - 20px);
}

footer {
  background-color: #3b337b;
  height: 20px;
}

.mainHeader {
  align-items: center;
  background-color: #3b337b;
  display: flex;
  padding: 30px;
  img {
    height: 60px;
  }
}

.secondaryHeader {
  margin-top: 25px;
  padding-left: 30px;
  padding-right: 30px;
  h1 {
    font-size: 20px;
    margin: 0;
  }
}

.disclaimer-title {
  font-size: 16px;
  margin: 0;
  margin-top: 10px;
  padding-left: 30px;
  padding-right: 30px;
}

.disclaimer {
  font-size: 14px;
  margin: 0;
  padding-left: 30px;
  padding-right: 30px;
}

.cta-container {
  align-items: center;
  display: flex;
  gap: 10px;
  margin-top: 20px;
  padding-left: 30px;
  padding-right: 30px;
  p {
    font-size: 13px;
  }
}

.button {
  background-color: #f29100;
  border-radius: 30px;
  border: none;
  color: white;
  cursor: pointer;
  display: inline-block;
  flex: 1;
  font-size: 14px;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  font-weight: bold;
  &:disabled {
    font-weight: normal;
    /* background-color: #bf5c0c; */
    color: rgba(255, 255, 255, 0.6);
    /* background-color: #a8aaba; */
    cursor: not-allowed;
    /* border-color: #a8aaba !important; */
  }
}

.whipDetailsImg {
  width: 100%;
  align-self: center;
  border-radius: 12px;
  height: auto;
}

.whipDetailsTitle {
  font-size: 14px;
  margin: 0;
  margin-top: 25px;
}

.whipDetailsSubTitle {
  font-size: 16px;
  margin: 0;
  margin-top: 5px;
}

.whipDetailsDescription {
  font-size: 12px;
}

section {
  padding-left: 30px;
  padding-right: 30px;
  border-top: 2px solid #f2f2f2;
  padding-top: 25px;
  margin-top: 30px;
  h2 {
    color: #3b337b;
    text-align: center;
    font-size: 18px;
    margin: 0;
    margin-bottom: 15px;
  }
}

.inputGroup {
  display: flex;
  align-items: center;
}

.inputSection {
  background-color: #f2f2f2;
  padding: 20px;
  border-radius: 12px;
  + .inputSection {
    margin-top: 20px;
  }
  p {
    margin: 0;
    margin-bottom: 10px;
    font-size: 14px;
  }
}

.inputGroup + .inputGroup {
  margin-top: 15px;
}

.input {
  background-color: white;
  border-radius: 12px;
  border: 2px solid #f29100;
  color: #0d1623;
  font-size: 14px;
  padding: 10px;
}

/*  */

.inputGroup .button {
  height: 48px;
  border-radius: 12px;
  border: 2px solid #f29100;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  font-size: 14px;
  margin-left: -1px;
  padding: 0;
  flex: 0;
  padding: 10px;
  text-align: center;
  min-width: 90px;
  display: block;
  white-space: nowrap;
}

.inputGroup .input {
  height: 48px;
  font-size: 18px;
  padding: 10.5px;
  text-align: center;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  flex: 1;
  width: 100%;
}

.inputGroup .button.editMode {
  border-color: #a8aaba;
  background-color: #a8aaba;
}

.inputGroup .input.editMode {
  color: #a8aaba;
  border-color: #a8aaba;
}

.inputGroup .step {
  display: inline-flex;
  width: 39px;
  height: 39px;
  border-radius: 100%;
  background-color: #f29100;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 15px;
  font-weight: bolder;
  margin-right: 5px;
  flex-shrink: 0;
  margin-left: -40px;
}

.inputGroup .step.done {
  background-color: #35b678;
}
